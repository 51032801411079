import React, {useState} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import Header from "../components/header";
import ReactMarkdown from "react-markdown";
import '../styles/pages/contact.css'
import MapContainer from "../components/mapContainer";
import $ from 'jquery'
import ReCAPTCHA from "react-google-recaptcha";
import {Helmet} from "react-helmet";
import '../styles/theme/global.css'

const Contact = ({data}) => {
    const contact = data.strapiPageContact
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [newsletter, setNewsletter] = useState()
    const [recaptcha, setRecaptcha] = useState()

    const recaptchaRef = React.createRef()

    function onChange(value) {
        setRecaptcha(`${value}`)
    }


    async function addMessage(e) {
        e.preventDefault();
        const jwt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjMxNTMxMTI4LCJleHAiOjE2MzQxMjMxMjh9.tmAzH2H-6KcfvUG_z7h1aL3-pCSROLAT3xi8e5ZLuEo"
        const coordonnees = {
            Name: name,
            Telephone: phone,
            Email: email,
            Message: message,
            InscriptionCollecteEtNewsLetter: newsletter,
        }
        if (recaptcha !== undefined) {
            const add = await fetch(`https://api.ebisurecords.fr/contact-entries`, {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(coordonnees)
            })
            const addResponse = await add.json
            console.log(addResponse)
            $('#contact-form')[0].reset()
            setName('')
            setPhone('')
            setEmail('')
            setMessage('')
            setNewsletter(false)
            $('.error-recaptcha').hide()
        } else {
            console.log('non')
            $('.error-recaptcha').show()
        }
    }

    function heightBugFix() {
        if (typeof document !== 'undefined') {
            $(document).ready(() => {
                if (window.location.pathname === '/contact') {
                    $('.container-map').children('div').children('div').attr('style', 'position: absolute; width: 100%;')
                    $('.tl-wrapper.tl-wrapper--mount.tl-wrapper-status--entered').attr('style', 'overflow: hidden; opacity: 1; z-index: 1')
                }
            })
        }
    }

    return (
        <>
            <Helmet>
                <title>Ebisu Records - Contact</title>
                <meta property={'og:image'} content={`https://www.ebisurecords.fr${contact.ImageSeo.publicURL}`}/>
                <meta name={"description"} content={contact.DescriptionSeo}/>
                <meta property={"og:title"} content={contact.TitleSeo}/>
                <meta property={"og:type"} content={"website"}/>
                <meta property={'og:description'} content={contact.DescriptionSeo}/>
                <meta property={"og:url"} content={'https://ebisurecords.fr'}/>
                <meta property={"og:site_name"} content={"ebisurecords"}/>
                <meta property={"og:locale"} content={"fr_FR"}/>
                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="twitter:title" content={contact.TitleSeo}/>
                <meta property="twitter:description" content={contact.DescriptionSeo}/>
                <meta property="twitter:image"
                      content={`https://www.ebisurecords.fr${contact.ImageSeo.publicURL}`}/>
            </Helmet>
            <Header data={data}/>
            <Layout className={'contact-page'}>
                <div className={'container-map'} style={{overflow: "hidden", position: 'relative'}}>
                    <MapContainer latitude={contact.CoordonneeGpsEntreprise.Latitude}
                                  longitude={contact.CoordonneeGpsEntreprise.Longitude}/>
                </div>
                <div className={'d-md-flex text-white mt-4'}>
                    <div className={'container-information-coordonee d-flex flex-column col-md-6'}>
                        <ReactMarkdown className={'contact-title'}>{contact.Title}</ReactMarkdown>
                        <div className={"background-contact"}>CONTACT</div>
                        <ReactMarkdown
                            className={'contact-description pr-md-5 pl-0 pt-2'}>{contact.Description}</ReactMarkdown>
                        <div
                            className={'container-coordonee full-width d-xl-flex justify-content-between pl-0 text-very-small'}>
                            <div className={'d-flex pt-3 full-width pr-2'}>
                                <span className={'mr-2 coordonne'}>Contact: </span>
                                <div className={'contact-container d-flex flex-column'}>
                                    {
                                        contact.Contact.map((information) => (
                                                information.Telephone !== 0 ?
                                                    <div key={information.id} className={'d-flex'}>
                                                        <div className={"pb-2"}>+(33){information.Telephone}</div>
                                                        <div className={'mr-1 ml-1'}>/</div>
                                                        <div>{information.Email}</div>
                                                    </div>
                                                    :
                                                    <div key={information.id} className={'d-flex'}>
                                                        <div>{information.Email}</div>
                                                    </div>
                                        ))
                                    }
                                        </div>
                                        </div>
                                        <div className={'d-flex pt-3 full-width'}>
                                        <span className={'mr-2 coordonne'}>Our Adress: </span>
                                        <div className={'d-flex flex-md-column'}>
                                        <div className={"pb-2"}>{contact.Adresse.Rue}</div>
                                        <div>{contact.Adresse.Complement}</div>
                                        </div>
                                        </div>
                                        </div>
                                        <div className={'mt-4'}>
                                        <span className={"mr-2 coordonne text-very-small"}>Follow us:</span>
                                        {
                                        contact.FollowUs.map((socialMedia) => (
                                        <a className={'link_rs'} key={socialMedia.id} href={socialMedia.Link} target={'_blank'}
                                        rel={"noreferrer"}>
                                        <img className={'logo-reaseau-social'}
                                        src={`${socialMedia.ImageSocialMedia.publicURL}`}
                                        alt="reseau social logo"/>
                                        </a>
                                        ))
                                        }
                                        </div>
                                        </div>
                                        <div className={'col-md-6 d-flex justify-content-center container-form-contact mt-3 mt-md-0'}>
                                        <form id={'contact-form'} onSubmit={e => addMessage(e)} method="POST"
                                        className={'d-flex flex-column justify-content-between full-width pb-2'}>
                                        <div className={'d-md-flex justify-content-between full-width mb-md-3'}>
                                        <div className={'form-group full-width'}>
                                        <input onChange={e => setName(e.target.value)} type={'text'}
                                        className={'form-control'} placeholder={'name'} required/>
                                        </div>
                                        <div className={'form-group full-width'}>
                                        <input onChange={e => setPhone(e.target.value)} value={phone} type={'tel'}
                                        className={'form-control'} placeholder={'Phone'}/>
                                        </div>
                                        </div>
                                        <div className={'d-md-flex justify-content-between'}>
                                        <div className={'form-group full-width'}>
                                        <input onChange={e => setEmail(e.target.value)} value={email} type={'email'}
                                        className={'form-control'} placeholder={'E-mail'} required/>
                                        </div>
                                        <div className={'form-group full-width'}>
                                        <textarea onChange={e => setMessage(e.target.value)} value={message}
                                        className={'form-control'} placeholder={'Message'} required
                                        style={{height: '36.5px'}}/>
                                        </div>
                                        </div>
                                        <div
                                        className={'full-width d-flex flex-row-reverse container-checkbox align-items-center justify-content-end'}>
                                        <label>En cochant cette case, j'accepte de m’inscrire à la newsletter de l'entreprise
                                        Ebisu Records et
                                        que l’entreprise collecte mes données pour réaliser des campagnes de
                                        prospection.</label>
                                        <input id={'checkbox-contact mr-2'} onChange={e => setNewsletter(e.target.checked)}
                                        type={'checkbox'}
                                        className={''}/>
                                        </div>
                                        <ReCAPTCHA
                                        sitekey="6LfIQQIbAAAAAHEJiLLeKXdAsB-g7DT3CSTi9_9U"
                                        onChange={onChange}
                                        ref={recaptchaRef}
                                        />
                                        <div className={'container-button-contact d-flex flex-column'}>
                                        <span className={"error-recaptcha"}
                                        style={{color: "red", fontSize: "12px", display: 'none'}}>Need recaptcha</span>
                                        <button type={'submit'} className={'button-form mt-3'}>{contact.TextButon}</button>
                                        </div>
                                        </form>
                                        </div>
                                        </div>
                                        {
                                        heightBugFix()
                                        }
                                        </Layout>
                                        </>
                                        )
                                        }

                                        export default Contact

                                        export const ContactQuery = graphql`
                                        query ContactQuery {
                                        strapiPageContact {
                                        CoordonneeGpsEntreprise {
                                        Latitude
                                        Longitude
                                        }
                                        id
                                        Adresse {
                                        Rue
                                        Complement
                                        }
                                        Title
                                        FollowUs {
                                        id
                                        Link
                                        ImageSocialMedia {
                                        publicURL
                                        }
                                        }
                                        TextButon
                                        Description
                                        Contact {
                                        id
                                        Email
                                        Telephone
                                        }
                                        TitleSeo
                                        ImageSeo {
                                        publicURL
                                        }
                                        DescriptionSeo
                                        }
                                        strapiHeader {
                                        id
                                        Logo {
                                        publicURL
                                        }
                                        ItemsNav {
                                        id
                                        Item
                                        }
                                        }
                                        strapiThemeChooser {
                                        Themes {
                                        button
                                        slug
                                        id
                                        title
                                        imageChooser {
                                        publicURL
                                        }
                                        }
                                        }
                                        }
                                        `